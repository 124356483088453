<template>
  <v-container 
    style="
      padding: 0;
      margin-top: 122px;
    " 
    fluid
  >

    <v-row 
      class="ma-15"

    >
      <v-row align="center" >
        <v-col>
          <v-card 
            flat
          >
            <v-card-title class="justify-center">
              <h1 class="font mb-15">
                Get Started
              </h1>
            </v-card-title>
            <v-card-text>
              <v-row align="center" justify="space-around">
              <v-col align-self="center" cols="12" md="6">
                <login-account />
              </v-col>
              <v-col align-self="center" cols="12" md="6">
                <register-account />
              </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-row>

    <Footer></Footer>

  </v-container>
</template>

<script>
import RegisterAccount from '@/components/RegisterAccount.vue'
import LoginAccount from '@/components/LoginAccount.vue'
// import StatusBar from '@/components/StatusBar.vue'
// import Footer from '@/components/Footer.vue'


import { mapState } from 'vuex'

export default {
  name: 'SignIn',
  data() {
    return {}
  },
  components: {
    RegisterAccount,
    LoginAccount,
    // StatusBar,
    // Footer,
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    // loggedIn() {
    //   this.$store.dispatch('loggedIn')
    // }
  },
  mounted() {
    window.scrollTo(0,0)
    // this.$store.dispatch('loggedIn')
  },
}
</script>

<style scoped>
</style>
