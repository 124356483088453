<template>
  <v-card class="ml-5">
    <v-card-title>
      <h1 v-if="showPasswordReset" class="font">
        Reset Password
      </h1>
      <h1 v-else class="font">
        Login
      </h1>
    </v-card-title>
    <v-card-text>
      <v-form v-if="showPasswordReset">
        <v-text-field
          v-model="email"
          prepend-icon="mdi-account-circle"
          label="Email"
          required
        ></v-text-field>
      </v-form>
      <v-form v-else>
        <v-text-field
          v-model="email"
          prepend-icon="mdi-account-circle"
          label="Email"
          required
        ></v-text-field>
        <v-text-field
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          label="Password"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          required
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-row>
      <v-col>
        <v-card-actions>
          <v-row>
            <v-col v-if="showPasswordReset">
              <v-btn class="mr-4" @click="sendResetEmail">Send Reset Email</v-btn>
              <v-btn  @click="resetPasswordScreen" class="mr-4">
                Back to Login
              </v-btn>
            </v-col>
            <v-col v-else>
              <v-btn class="mr-4" @click="loginAccount">Login</v-btn>
              <v-btn @click="resetPasswordScreen" class="mr-4">
                Forgot your password?
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { Auth } from '../firebase/auth'

export default {
  name: 'LoginAccount',
  data() {
    return {
      email: '',
      password: '',
      showPassword: false,
      showPasswordReset: false,
    }
  },
  methods: {
    loginAccount() {
      this.$store.dispatch('loginAccount', {
        email: this.email,
        password: this.password,
      })
    },
    resetPasswordScreen() {
      this.showPasswordReset = !this.showPasswordReset
    },
    sendResetEmail() {
      Auth.sendPasswordResetEmail(this.email).then(() => {
        this.email = ''
        alert('Reset email sent :)')
      }).catch((err) => {
        alert(err)
      })
    }
  },
}
</script>

<style scoped>
.font {
  font-family: "NunitoSans-Light";
  color: #77787B;
  font-size: 1.5em;
}
</style>
